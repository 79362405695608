import React from 'react'
import { graphql, navigate } from 'gatsby'
import { TransitionState } from 'gatsby-plugin-transition-link'
import posed from 'react-pose'
import { motion } from "framer-motion"

import { useSwipeable } from "react-swipeable";

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import TriggerButton from '../components/TriggerButton'
import Button from '../components/Button'
import Pagination from '../components/Pagination'

const PageFade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
})
const elementFade = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
}
const sequenceFade = {
  hidden: {
    opacity: 0,
    y: "10px"
  },
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: .6,
      delay: i * 0.3,
      ease: "easeInOut"
    },
  })
}

const BusinessPageTemplate = (props) => {

  const handlers = useSwipeable({
    onSwipedLeft: () => paginationData[0].next ? navigate(paginationData[0].next.uid) : null,
    onSwipedRight: () => paginationData[0].previous ? navigate(paginationData[0].previous.uid) : null,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  // console.log("BusinessUnitTemplate props:", props)
  const metadata = props.data.businesspage.metadata
  const columns = props.data.columns.nodes[0].data.columns
  const { uid } = props.data.prismicColumn
  const businessUnits= props.data.prismicColumn.data.business_units
  const { background_colour } = props.data.prismicColumn.data

  var paginationData = props.data.allPrismicColumn.edges.filter(edge => {
    return edge.node.uid === uid
  });

  return (
    <Layout props={props}>
      <TransitionState>
        {({ transitionStatus }) => (
          <PageFade
            pose={
              ["entering", "entered"].includes(transitionStatus)
                ? "visible"
                : "hidden"
            }
          >
            <SEO
              locale={props.pageContext.lang}
              title={metadata.metatitle}
              description={metadata.description}
              keywords={metadata.keywords}
              image={metadata.metadata_image.localFile.childImageSharp.fixed.src}
              pathname={props.location.pathname}
              origin={props.location.origin}
            />
            <motion.div
              className={`business-page ${businessUnits.length === 1  ? 'business-page--single' : 'business-page--double'}`}
              style={{ backgroundColor: background_colour }}
              initial="hidden"
              animate="visible"
              variants={elementFade}>

              <TriggerButton icon="close" uid={uid} color="white" url="/" />

              <div {...handlers} className="business-page__content is-relative">
                <div className={`business-units columns is-desktop ${businessUnits.length === 1  ? 'columns--single' : 'columns--double'}`}>
                  {businessUnits.map((product, index) => {
                    return (
                      <div className=
                        {`business-unit column is-relative
                          ${businessUnits.length === 1 ? 'is-10-touch is-11-desktop is-offset-1-mobile is-offset-1' : ''}
                          ${businessUnits.length === 2 && index === 0 ? 'is-10-touch is-5-desktop is-offset-1-mobile is-offset-1' : ''}
                          ${businessUnits.length === 2 && index === 1 ? 'is-10-touch is-5-desktop is-offset-1-touch' : ''}
                        `}
                          key={index}>
                        <div className="business-unit__content content-">
                          <motion.h2 className="business-unit__title title is-size-3 is-size-2-tablet is-size-1-fullhd"
                          custom={`${businessUnits.length === 1 ? index+1 : index+2}`}
                          initial="hidden"
                          animate="visible"
                          variants={sequenceFade}>
                            {product.header.text}
                          </motion.h2>
                          <motion.div
                            className="business-unit__copy"
                            custom={`${businessUnits.length === 1 ? index+2 : index+3}`}
                            initial="hidden"
                            animate="visible"
                            variants={sequenceFade}>
                            {product.copy.text}
                            </motion.div>
                          <motion.div
                            className="business-unit__cta"
                            custom={`${businessUnits.length === 1 ? index+3 : index+4}`}
                            initial="hidden"
                            animate="visible"
                            variants={sequenceFade}>
                            <img
                              className="business-unit__logo"
                              src={product.logo.url}
                              alt={product.logo.alt} />
                            <Button
                              backgroundColor={background_colour}
                              product={product}/>
                          </motion.div>
                        </div>
                        <motion.div
                          className="business-unit__bg"
                          style={{backgroundImage: `url(${product.promo_image.localFile.childImageSharp.fluid.src})`}}
                          custom={`${businessUnits.length === 1 ? index+5 : index+1}`}
                          initial="hidden"
                          animate="visible"
                          variants={sequenceFade}>
                        </motion.div>
                      </div>
                    )
                  })}
                </div>
                <Pagination
                  paginationData={paginationData[0]}
                  uid={uid}
                  columns={columns}
                  backgroundColor={background_colour}
                  locale={props.pageContext.lang}
                />
              </div>

            </motion.div>

          </PageFade>
        )}
      </TransitionState>
    </Layout>
  )
}

export const productData = graphql`
  query ProductQuery($id: String! $uid: String! $lang: String!) {
    businesspage: prismicColumnBodyMetadata(id: {eq: $id}) {
      id
      metadata: primary {
        metatitle
        description
        keywords
        metadata_image {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
    columns: allPrismicHome(filter: {lang: {eq: $lang}}) {
      nodes {
        data {
          columns {
            column {
              uid
            }
          }
        }
      }
    }
    allPrismicColumn(filter: {lang: {eq: $lang}}, sort: {fields: data___carousel_order}) {
      edges {
        node {
          uid
        }
        previous {
          uid
          data {
            title {
              text
            }
          }
        }
        next {
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
    prismicColumn(uid: { eq: $uid }, lang: {eq: $lang}) {
      uid
      data {
        background_colour
        title {
          text
        }
        business_units {
          header {
            text
          }
          copy {
            text
          }
          url {
            url
            target
          }
          url_copy {
            text
          }
          logo {
            url
          }
          promo_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400, pngQuality: 80) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BusinessPageTemplate
