import React from 'react'
import styled from 'styled-components'

const SimpleButton = styled.a`
  &:hover{
    .button__text{
      color: ${props => props.backgroundColor}
    }
  }
`

const Button = (props) => {
  return (
    <SimpleButton 
      className="button is-rounded is-size-5-tablet is-size-4-widescreen" 
      href={props.product.url.url} 
      target={props.product.url.target}
      backgroundColor={props.backgroundColor}>
      <span className="button__text">{props.product.url_copy.text}</span>
      <span className="icon">
        <i></i>
      </span>
    </SimpleButton>
  )
}

export default Button
