import React from 'react'
import styled from 'styled-components'
import TransitionLink from "gatsby-plugin-transition-link"

const BusinessPagination = styled.div`
  &::before{
    background-color: ${props => props.backgroundColor};
  }
`

const Pagination = (props) => {

  return (
    <BusinessPagination
      className="business-pagination"
      backgroundColor={props.backgroundColor}
    >
      {props.paginationData.previous &&
        <TransitionLink
          className="pagination__button pagination__button--previous"
          to={props.paginationData.previous.uid}
          exit={{ length: 0.5 }}>
            <i></i>
            <span>{props.paginationData.previous.data.title.text}</span>
        </TransitionLink>
      }
      <div className="pagination__nav">
        {props.columns.map(({column}, index) => {
          return (
            <TransitionLink
              className={`pagination__item ${props.uid === column.uid ? 'active' : ''}`}
              to={column.uid}
              exit={{ length: 0.5 }}
              key={index}>
              <div className="pagination__tooltip is-size-5">{column.uid}</div>
            </TransitionLink>
          )
        })}
      </div>
      {props.paginationData.next &&
        <TransitionLink
          className="pagination__button pagination__button--next"
          to={props.paginationData.next.uid}
          exit={{ length: 0.5 }}>
            <span>{props.paginationData.next.data.title.text}</span>
            <i></i>
        </TransitionLink>
      }

    </BusinessPagination>
  )
}

export default Pagination
